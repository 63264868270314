import React from "react";
import { AnschreibenGuide } from "./anschreiben-guide";
import { LebenslaufGuide } from "./lebenslauf-guide";
import { KINutzung } from "./ki-nutzung";
import { AnschreibenNotwendig } from "./anschreiben-notwendig";
import { Berufseinsteiger } from "./berufseinsteiger";
import { AnschreibenVorlagen } from "./anschreiben-vorlagen";
import { LebenslaufVorlagen } from "./lebenslauf-vorlagen";
import { LebenslaufEnglisch } from "./lebenslauf-englisch";
import { HobbysLebenslauf } from "./hobbys-lebenslauf";
import { BlogHome } from "./blog-home";
import { BlogRoute } from "../route";
import { BlogPostKind } from "../route";

export type BlogMeta = {
  title: string;
  description: string;
  datePublished: string;
  lastModified?: string;
  author: string;
  keywords: readonly string[];
  readingTime: string;
};

export type PostStyles = {
  container: React.CSSProperties;
  header: {
    title: React.CSSProperties;
    meta: React.CSSProperties;
  };
  content: {
    section: React.CSSProperties;
    paragraph: React.CSSProperties;
    list: React.CSSProperties;
    listItem: React.CSSProperties;
    highlight: React.CSSProperties;
    note: React.CSSProperties;
  };
};

export type BlogPostProps = {
  meta: BlogMeta;
  styles: PostStyles;
  children: React.ReactNode;
};

export const styles: PostStyles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "2rem",
    color: "#2D3748", // Darker base text color
    fontSize: "1.1rem", // Slightly larger base font
  },
  header: {
    title: {
      fontSize: "2.75rem",
      fontWeight: "bold",
      marginBottom: "1.5rem",
      color: "#1A202C", // Even darker for title
      lineHeight: 1.2,
    },
    meta: {
      color: "#718096",
      marginBottom: "3rem",
      fontSize: "1rem",
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
    },
  },
  content: {
    section: {
      marginBottom: "3rem", // More spacing between sections
    },
    paragraph: {
      lineHeight: 1.8,
      marginBottom: "1.5rem",
    },
    list: {
      marginLeft: "1.5rem",
      marginBottom: "1.5rem",
    },
    listItem: {
      marginBottom: "0.75rem",
      position: "relative",
    },
    highlight: {
      backgroundColor: "#EBF8FF", // Light blue background
      padding: "1.5rem",
      borderRadius: "8px",
      marginBottom: "1.5rem",
      boxShadow: "0 2px 4px rgba(0,0,0,0.04)",
    },
    note: {
      borderLeft: "4px solid #4299E1", // Brighter blue
      paddingLeft: "1.5rem",
      marginBottom: "1.5rem",
      backgroundColor: "#F7FAFC", // Very light gray-blue
      padding: "1.5rem",
      borderRadius: "0 8px 8px 0",
    },
  },
};

export const headingStyles = {
  h2: {
    fontSize: "2rem",
    fontWeight: "bold",
    color: "#2D3748",
    marginTop: "2.5rem",
    marginBottom: "1.5rem",
    lineHeight: 1.3,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: "600",
    color: "#2D3748",
    marginTop: "2rem",
    marginBottom: "1rem",
    lineHeight: 1.4,
  },
};

export const linkStyles = {
  color: "#4299E1",
  textDecoration: "none",
  borderBottom: "1px solid transparent",
  transition: "border-color 0.2s ease",
  ":hover": {
    borderBottomColor: "#4299E1",
  },
};

export function BlogPost({ meta, styles, children }: BlogPostProps) {
  return (
    <article style={styles.container}>
      <header>
        <h1 style={styles.header.title}>{meta.title}</h1>
        <div style={styles.header.meta}>
          <time dateTime={meta.datePublished}>
            {new Date(meta.datePublished).toLocaleDateString("de-DE")}
          </time>
          <span>·</span>
          <span>{meta.author}</span>
        </div>
      </header>
      {children}
    </article>
  );
}

export const H2: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <h2 style={headingStyles.h2}>{children}</h2>
);

export const H3: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <h3 style={headingStyles.h3}>{children}</h3>
);

export type BlogProps = {
  blogRoute: BlogRoute;
};

export const BLOG_META: { readonly [K in BlogPostKind]: BlogMeta } = {
  "hobbys-lebenslauf": {
    title: "Hobbys im Lebenslauf: Diese Interessen überzeugen Personaler 2024",
    description:
      "Erfahre, welche Hobbys in deinen Lebenslauf gehören und wie du Freizeitaktivitäten überzeugend als relevante Kompetenzen präsentierst",
    datePublished: "2024-11-14",
    author: "Maria Weber",
    readingTime: "6 min",
    keywords: [
      "Lebenslauf Hobbys",
      "Hobbys Lebenslauf",
      "Interessen Lebenslauf",
      "Lebenslauf Freizeitaktivitäten",
      "Hobbys Bewerbung",
    ],
  },
  "lebenslauf-englisch": {
    title:
      "Lebenslauf auf Englisch: Von deutscher Struktur zu internationalem CV",
    description:
      "Guide für deinen englischen Lebenslauf: Vermeide typische Übersetzungsfehler und erstelle einen überzeugenden internationalen CV",
    datePublished: "2024-12-03",
    author: "Maria Weber",
    readingTime: "7 min",
    keywords: [
      "Lebenslauf Englisch",
      "CV Englisch",
      "Lebenslauf übersetzen",
      "Englischer Lebenslauf",
      "International CV",
    ],
  },
  "lebenslauf-vorlagen": {
    title:
      "Lebenslauf von der Stange? Warum Standard-Vorlagen dein Potenzial verstecken",
    description:
      "Erfahre, wie du deinen Lebenslauf von einer Standard-Vorlage zu einem überzeugenden Profil entwickelst, das deine wahren Stärken zeigt",
    datePublished: "2024-01-25",
    author: "Maria Weber",
    readingTime: "8 min",
    keywords: [
      "Lebenslauf Vorlage",
      "Lebenslauf Muster",
      "Lebenslauf erstellen",
      "CV Vorlage",
      "Bewerbung Lebenslauf",
    ],
  },
  "anschreiben-vorlagen": {
    title: "Anschreiben Vorlagen 2024: Warum sie deine Chancen sabotieren",
    description:
      "Erfahre, warum Standard-Vorlagen für dein Anschreiben mehr schaden als nutzen und wie du stattdessen überzeugst",
    datePublished: "2024-01-25",
    author: "Maria Weber",
    readingTime: "8 min",
    keywords: [
      "Anschreiben Vorlage",
      "Bewerbung Vorlagen",
      "Anschreiben Muster",
      "Bewerbung Beispiele",
      "Anschreiben schreiben",
    ],
  },
  berufseinsteiger: {
    title:
      "Lebenslauf für Berufseinsteiger: So überzeugst du ohne Berufserfahrung",
    description:
      "Dein Guide für einen überzeugenden Berufseinsteiger-Lebenslauf: Praktische Tipps und Beispiele, wie du dein Potenzial optimal präsentierst",
    datePublished: "2024-11-06",
    author: "Maria Weber",
    readingTime: "8 min",
    keywords: [
      "Lebenslauf Berufseinsteiger",
      "Bewerbung ohne Berufserfahrung",
      "Erster Job Bewerbung",
      "Lebenslauf Absolvent",
      "Berufsanfänger Bewerbung",
    ],
  },
  "anschreiben-notwendig": {
    title: "Bewerbung ohne Anschreiben: Wann du darauf verzichten kannst",
    description:
      "Anschreiben: Zeitverschwendung oder wichtiger erster Eindruck? Der komplette Guide für die richtige Entscheidung und moderne Alternativen",
    datePublished: "2024-10-04",
    author: "Maria Weber",
    readingTime: "6 min",
    keywords: [
      "Bewerbung ohne Anschreiben",
      "Anschreiben notwendig",
      "Moderne Bewerbung",
      "Anschreiben 2024",
      "Bewerbung Anschreiben",
    ],
  },
  "ki-nutzung": {
    title:
      "It's a (Job-)Match! Warum du KI deine Bewerbung schreiben lassen solltest",
    description:
      "Moderne Bewerbungsprozesse verstehen und optimieren: Warum KI-Unterstützung keine Trickserei ist, sondern dir hilft, deine Qualifikationen optimal zu präsentieren",
    datePublished: "2024-12-09",
    author: "Maria Weber",
    readingTime: "9 min",
    keywords: [
      "KI Bewerbung",
      "Bewerbung schreiben",
      "ChatGPT Bewerbung",
      "Künstliche Intelligenz Bewerbung",
      "Bewerbung optimieren",
    ],
  },
  "anschreiben-guide": {
    title: "Bewerbung schreiben: So gelingt dein Anschreiben",
    description:
      "Der Leitfaden für überzeugende Anschreiben: Praxisnahe Tipps, Beispiele und professionelle Unterstützung durch KI",
    datePublished: "2024-07-04",
    author: "Maria Weber",
    readingTime: "10 min",
    keywords: [
      "Anschreiben",
      "Bewerbung schreiben",
      "Bewerbungsanschreiben",
      "Motivationsschreiben",
    ],
  },
  "lebenslauf-guide": {
    title: "Lebenslauf schreiben: Tipps für einen überzeugenden CV",
    description:
      "Der komplette Guide für deinen Lebenslauf: Von Grundlagen bis Expertentipps, inklusive Branchen-Besonderheiten und praktischer Checkliste",
    datePublished: "2024-08-12",
    author: "Maria Weber",
    readingTime: "12 min",
    keywords: [
      "Lebenslauf",
      "Lebenslauf schreiben",
      "CV erstellen",
      "Bewerbung Lebenslauf",
      "Curriculum Vitae",
    ],
  },
} as const;

export function Blog({ blogRoute }: BlogProps) {
  switch (blogRoute.kind) {
    case "home":
      return <BlogHome />;
    case "hobbys-lebenslauf":
      return (
        <BlogPost meta={BLOG_META["hobbys-lebenslauf"]} styles={styles}>
          {" "}
          <HobbysLebenslauf />{" "}
        </BlogPost>
      );
    case "lebenslauf-englisch":
      // return <LebenslaufEnglisch />;
      return (
        <BlogPost meta={BLOG_META["lebenslauf-englisch"]} styles={styles}>
          {" "}
          <LebenslaufEnglisch />{" "}
        </BlogPost>
      );
    case "lebenslauf-vorlagen":
      return (
        <BlogPost meta={BLOG_META["lebenslauf-vorlagen"]} styles={styles}>
          {" "}
          <LebenslaufVorlagen />{" "}
        </BlogPost>
      );
    case "anschreiben-vorlagen":
      return (
        <BlogPost meta={BLOG_META["anschreiben-vorlagen"]} styles={styles}>
          {" "}
          <AnschreibenVorlagen />{" "}
        </BlogPost>
      );
    case "berufseinsteiger":
      return (
        <BlogPost meta={BLOG_META["berufseinsteiger"]} styles={styles}>
          {" "}
          <Berufseinsteiger />{" "}
        </BlogPost>
      );
    case "anschreiben-notwendig":
      return (
        <BlogPost meta={BLOG_META["anschreiben-notwendig"]} styles={styles}>
          {" "}
          <AnschreibenNotwendig />{" "}
        </BlogPost>
      );
    case "ki-nutzung":
      return (
        <BlogPost meta={BLOG_META["ki-nutzung"]} styles={styles}>
          {" "}
          <KINutzung />{" "}
        </BlogPost>
      );
    case "anschreiben-guide":
      return (
        <BlogPost meta={BLOG_META["anschreiben-guide"]} styles={styles}>
          {" "}
          <AnschreibenGuide />{" "}
        </BlogPost>
      );
    case "lebenslauf-guide":
      return (
        <BlogPost meta={BLOG_META["lebenslauf-guide"]} styles={styles}>
          {" "}
          <LebenslaufGuide />{" "}
        </BlogPost>
      );
    default: {
      const _exhaustiveCheck: never = blogRoute;
      throw new Error(`Unhandled blog route kind: ${_exhaustiveCheck}`);
    }
  }
}
